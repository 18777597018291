// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul {
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

.component-container {
  width: 100%;
  background-color: #29A6de;
  padding: 1em;
}

.info {
  width: 100%;
  text-align: center;
}
.about,
.contact {
  width: 100%;
}
.link {
  text-decoration: underline;
  text-decoration-style: dotted;
}

.footer {
  text-align: center;
  width: 100%;
  font-size: 0.8em;
}
.footer div {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/footer/footer.component.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;AACA;;EAEE,WAAW;AACb;AACA;EACE,0BAA0B;EAC1B,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,WAAW;AACb","sourcesContent":["ul {\r\n  text-decoration: none;\r\n  list-style: none;\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n\r\n.component-container {\r\n  width: 100%;\r\n  background-color: #29A6de;\r\n  padding: 1em;\r\n}\r\n\r\n.info {\r\n  width: 100%;\r\n  text-align: center;\r\n}\r\n.about,\r\n.contact {\r\n  width: 100%;\r\n}\r\n.link {\r\n  text-decoration: underline;\r\n  text-decoration-style: dotted;\r\n}\r\n\r\n.footer {\r\n  text-align: center;\r\n  width: 100%;\r\n  font-size: 0.8em;\r\n}\r\n.footer div {\r\n  width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { NgModule } from '@angular/core';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { FlexLayoutModule } from 'ngx-flexible-layout';


@NgModule({
  exports: [
    FlexLayoutModule,

    MatSidenavModule,
    MatButtonModule,
    MatTabsModule,
    MatDividerModule
  ]
})
export class TpAngularMaterialModule { }

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.component-container {
  width: 100%;
  overflow-y: auto;
}

.sidenav {
  width: 200px;
  box-shadow: 3px 0 6px rgba(0,0,0,.24);
}

#page-content-container {
  width: 100%;
  background: none;
}

/*
https://www.sessions.edu/color-calculator/
*/
#page-content {
  max-width: 960px;
  margin: auto;
  padding: 1em;
  padding-bottom: 4em;
  background-color: #ffffff;
  box-shadow: 8px -7px 19px 1px rgba(0,0,0,0.16);
  
}

#header-content,
#footer-content {
  width: 100%;
  max-width: 960px;
  margin: auto;
}


.mat-drawer-container {
  background-color: rgba(0, 0, 0, 0);
  
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,qCAAqC;AACvC;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;;CAEC;AACD;EACE,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EAIzB,8CAA8C;;AAEhD;;AAEA;;EAEE,WAAW;EACX,gBAAgB;EAChB,YAAY;AACd;;;AAGA;EACE,kCAAkC;;AAEpC","sourcesContent":[".component-container {\r\n  width: 100%;\r\n  overflow-y: auto;\r\n}\r\n\r\n.sidenav {\r\n  width: 200px;\r\n  box-shadow: 3px 0 6px rgba(0,0,0,.24);\r\n}\r\n\r\n#page-content-container {\r\n  width: 100%;\r\n  background: none;\r\n}\r\n\r\n/*\r\nhttps://www.sessions.edu/color-calculator/\r\n*/\r\n#page-content {\r\n  max-width: 960px;\r\n  margin: auto;\r\n  padding: 1em;\r\n  padding-bottom: 4em;\r\n  background-color: #ffffff;\r\n\r\n  -webkit-box-shadow: 8px -7px 19px 1px rgba(0,0,0,0.16);\r\n  -moz-box-shadow: 8px -7px 19px 1px rgba(0,0,0,0.16);\r\n  box-shadow: 8px -7px 19px 1px rgba(0,0,0,0.16);\r\n  \r\n}\r\n\r\n#header-content,\r\n#footer-content {\r\n  width: 100%;\r\n  max-width: 960px;\r\n  margin: auto;\r\n}\r\n\r\n\r\n.mat-drawer-container {\r\n  background-color: rgba(0, 0, 0, 0);\r\n  \r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

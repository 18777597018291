import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'footer-component',
  templateUrl: './footer.component.html',
  styleUrls: [
    './footer.component.css',
    './footer.component.responsive.css'
  ]
})
export class FooterComponent {
  public get year(): number { return (new Date()).getFullYear(); }
  public get emailHref(): string { return `mailto:${environment.email}`; }
  public get email(): string { return environment.email; }
}

import { Component } from '@angular/core';

@Component({
  selector: 'header-component',
  templateUrl: './header.component.html',
  styleUrls: [
    './header.component.css',
    './header.component.responsive.css'
  ]
})
export class HeaderComponent {

}

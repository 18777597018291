// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 599px) {
  .footer {
    font-size: 0.6em;
  }
}




/*
breakpoint	mediaQuery
xs	        'screen and (max-width: 599px)'
sm	        'screen and (min-width: 600px) and (max-width: 959px)'
md	        'screen and (min-width: 960px) and (max-width: 1279px)'
lg	        'screen and (min-width: 1280px) and (max-width: 1919px)'
xl	        'screen and (min-width: 1920px) and (max-width: 5000px)'
lt-sm	    'screen and (max-width: 599px)'
lt-md	    'screen and (max-width: 959px)'
lt-lg	    'screen and (max-width: 1279px)'
lt-xl	    'screen and (max-width: 1919px)'
gt-xs	    'screen and (min-width: 600px)'
gt-sm	    'screen and (min-width: 960px)'
gt-md	    'screen and (min-width: 1280px)'
gt-lg	    'screen and (min-width: 1920px)'
*/`, "",{"version":3,"sources":["webpack://./src/app/components/footer/footer.component.responsive.css"],"names":[],"mappings":"AAAA;EACE;IACE,gBAAgB;EAClB;AACF;;;;;AAKA;;;;;;;;;;;;;;;CAeC","sourcesContent":["@media screen and (max-width: 599px) {\r\n  .footer {\r\n    font-size: 0.6em;\r\n  }\r\n}\r\n\r\n\r\n\r\n\r\n/*\r\nbreakpoint\tmediaQuery\r\nxs\t        'screen and (max-width: 599px)'\r\nsm\t        'screen and (min-width: 600px) and (max-width: 959px)'\r\nmd\t        'screen and (min-width: 960px) and (max-width: 1279px)'\r\nlg\t        'screen and (min-width: 1280px) and (max-width: 1919px)'\r\nxl\t        'screen and (min-width: 1920px) and (max-width: 5000px)'\r\nlt-sm\t    'screen and (max-width: 599px)'\r\nlt-md\t    'screen and (max-width: 959px)'\r\nlt-lg\t    'screen and (max-width: 1279px)'\r\nlt-xl\t    'screen and (max-width: 1919px)'\r\ngt-xs\t    'screen and (min-width: 600px)'\r\ngt-sm\t    'screen and (min-width: 960px)'\r\ngt-md\t    'screen and (min-width: 1280px)'\r\ngt-lg\t    'screen and (min-width: 1920px)'\r\n*/"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*gt-md*/
@media screen and (min-width: 960px){
  #page-content {
      width: 960px;
  }
}

/*lt-md*/
@media screen and (max-width: 959px){
  #page-content {
      width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.responsive.css"],"names":[],"mappings":"AAAA,QAAQ;AACR;EACE;MACI,YAAY;EAChB;AACF;;AAEA,QAAQ;AACR;EACE;MACI,WAAW;EACf;AACF","sourcesContent":["/*gt-md*/\r\n@media screen and (min-width: 960px){\r\n  #page-content {\r\n      width: 960px;\r\n  }\r\n}\r\n\r\n/*lt-md*/\r\n@media screen and (max-width: 959px){\r\n  #page-content {\r\n      width: 100%;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path: 'classes', loadChildren: () => import('./modules/classes/classes.module').then(m => m.ClassesModule) },
  { path: 'health-and-safety', loadChildren: () => import('./modules/health-and-safety/health-and-safety.module').then(m => m.HealthAndSafetyModule) },
  { path: 'instructors', loadChildren: () => import('./modules/instructors/instructors.module').then(m => m.InstructorsModule) },
  { path: 'equipment', loadChildren: () => import('./modules/equipment/equipment.module').then(m => m.EquipmentModule) },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, scrollPositionRestoration:'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

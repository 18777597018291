import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.css',
    './app.component.responsive.css'
  ]
})
export class AppComponent implements OnInit {

  constructor(private readonly router: Router) {
  }

  // https://github.com/angular/angular-cli/issues/14553
  @ViewChild('content', {static: true}) public content: ElementRef;

  public ngOnInit(): void {
    this.router.events.subscribe((evt: any) => {
      if (evt instanceof NavigationStart){
        if (this.content && this.content.nativeElement) {
          this.content.nativeElement.scrollTo(0,0);
        }
      }
    });
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.component-container {
  background-color: white;
  text-align: center;
  padding-top: 0.5em;
  box-shadow: 8px -7px 19px 1px rgba(0,0,0,0.16);
  
}

.logo {
  margin: auto;
  max-width: 200px;
  height: auto;
}

.title {
  font-size: 1.8em;
  font-weight: bold;
  color: #29A6de;
  padding: 0 1em;
  text-shadow: 8px 3px 14px rgba(0,0,0,0.22);
}


.nav {
  width: 100%;
  background-color: #3561f0;
  font-size: 1.5em;
  font-weight: bold;

}
.nav a {
  width: 100%;
  height: 100%;
  color: white;
  text-shadow: 1px 2px 2px #000000;
  border: 1px solid white;
  padding: 0.25em;
  vertical-align: middle;
  box-shadow: 0px 11px 7px -2px rgba(0,0,0,0.56);


  
}
.nav a:hover,
.nav .active-link {
  cursor: pointer;
  background-color: #A584F5;
  color: black;
  text-shadow: 1px 2px 2px #CECECE;
}

.link-container {
  padding: 0;
  margin: 0;


  
}`, "",{"version":3,"sources":["webpack://./src/app/components/header/header.component.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;EAGlB,8CAA8C;;AAEhD;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;EACd,cAAc;EACd,0CAA0C;AAC5C;;;AAGA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;EAChB,iBAAiB;;AAEnB;AACA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,gCAAgC;EAChC,uBAAuB;EACvB,eAAe;EACf,sBAAsB;EAGtB,8CAA8C;;;;AAIhD;AACA;;EAEE,eAAe;EACf,yBAAyB;EACzB,YAAY;EACZ,gCAAgC;AAClC;;AAEA;EACE,UAAU;EACV,SAAS;;;;AAIX","sourcesContent":[".component-container {\r\n  background-color: white;\r\n  text-align: center;\r\n  padding-top: 0.5em;\r\n  -webkit-box-shadow: 8px -7px 19px 1px rgba(0,0,0,0.16);\r\n  -moz-box-shadow: 8px -7px 19px 1px rgba(0,0,0,0.16);\r\n  box-shadow: 8px -7px 19px 1px rgba(0,0,0,0.16);\r\n  \r\n}\r\n\r\n.logo {\r\n  margin: auto;\r\n  max-width: 200px;\r\n  height: auto;\r\n}\r\n\r\n.title {\r\n  font-size: 1.8em;\r\n  font-weight: bold;\r\n  color: #29A6de;\r\n  padding: 0 1em;\r\n  text-shadow: 8px 3px 14px rgba(0,0,0,0.22);\r\n}\r\n\r\n\r\n.nav {\r\n  width: 100%;\r\n  background-color: #3561f0;\r\n  font-size: 1.5em;\r\n  font-weight: bold;\r\n\r\n}\r\n.nav a {\r\n  width: 100%;\r\n  height: 100%;\r\n  color: white;\r\n  text-shadow: 1px 2px 2px #000000;\r\n  border: 1px solid white;\r\n  padding: 0.25em;\r\n  vertical-align: middle;\r\n  -webkit-box-shadow: 0px 11px 7px -2px rgba(0,0,0,0.56);\r\n  -moz-box-shadow: 0px 11px 7px -2px rgba(0,0,0,0.56);\r\n  box-shadow: 0px 11px 7px -2px rgba(0,0,0,0.56);\r\n\r\n\r\n  \r\n}\r\n.nav a:hover,\r\n.nav .active-link {\r\n  cursor: pointer;\r\n  background-color: #A584F5;\r\n  color: black;\r\n  text-shadow: 1px 2px 2px #CECECE;\r\n}\r\n\r\n.link-container {\r\n  padding: 0;\r\n  margin: 0;\r\n\r\n\r\n  \r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
